<template>
  <Disclosure
    as="nav"
    class="bg-white max-w-8xl m-auto pb-6"
    v-slot="{ open }"
  >
    <div class="max-w-full mx-auto pl-4 mt-5">
      <div
        class="flex justify-between h-8 text-gray text-xs lg:text-sm"
      >
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <NuxtLink to="/">
              <nuxt-img
                sizes="sm:90px md:100px lg:150px"
                alt="Justhire Logo"
                src="/images/logos/just-hire-logo.svg"
              />
            </NuxtLink>
          </div>
        </div>
        <div
          class="flex hidden md:ml-6 md:flex md:space-x-3 lg:space-x-9 font-semibold text-blue-steel"
        >
          <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
          <span class="pb-3">
            <NuxtLink
              to="/booking/location"
              class="inline-flex items-center px-1 pt-1 hover:text-green"
            >
              Booking
            </NuxtLink>
          </span>
          <span
            @click="showEquipmentMenu = !showEquipmentMenu"
            class="pb-3"
          >
            <NuxtLink
              to="#"
              class="border-transparent inline-flex items-center px-1 pt-1 hover:text-green"
            >
              Equipment <ChevronDownIcon class="h-4 w-4" />
            </NuxtLink>
            <!-- Dropdown -->
            <div
              v-if="showEquipmentMenu"
              class="border bg-white rounded-[20px] border-blue-light-two mx-2 text-gray w-80 font-normal shadow-base absolute top-16 z-10"
            >
              <ul class="p-8">
                <li>
                  <NuxtLink
                    to="/equipment/categories/waste"
                    class="hover:text-green"
                    >Waste</NuxtLink
                  >
                </li>
              </ul>
            </div>
          </span>
          <span class="pb-3">
            <NuxtLink
              class="inline-flex items-center px-1 pt-1 hover:text-green"
              to="/about-us"
            >
              About
            </NuxtLink>
          </span>
          <span class="pb-3">
            <NuxtLink
              class="inline-flex items-center px-1 pt-1 hover:text-green"
              to="/knowledge"
            >
              Knowledge
            </NuxtLink>
          </span>
          <span class="pb-3">
            <NuxtLink
              class="inline-flex items-center px-1 pt-1 hover:text-green"
              to="/contact-us"
            >
              Contact
            </NuxtLink>
          </span>
        </div>
        <div class="flex items-center hidden md:flex">
          <div class="flex-shrink-0 pr-4">
            <!-- <a href="#" class="mr-4 lg:mr-8 text-blue-500 font-bold"
              >Log in</a
            > -->
            <NuxtLink to="https://marketplace-app.justhire.com/login">
              <button
                type="button"
                class="inline-flex px-3 lg:px-10 py-2 lg:py-3.5 items-center text-white text-sm font-semibold bg-blue-500 border-none rounded-lg tracking-wide"
              >
                LOG IN
              </button>
            </NuxtLink>
          </div>
        </div>
        <div class="-ml-2 mr-2 flex items-center md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 rounded-md text-gray hover:text-gray hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XMarkIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden pt-16" v-slot="{ close }">
      <div class="pt-2 px-4 pb-3 space-y-1">
        <NuxtLink
          to="/booking/location"
          class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
        >
          <h5>Booking</h5>
        </NuxtLink>
        <NuxtLink
          @click="close()"
          to="/equipment/categories/waste"
          class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
        >
          <h5>Waste</h5>
        </NuxtLink>
        <NuxtLink
          @click="close()"
          to="/about-us"
          class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
        >
          <h5>About</h5>
        </NuxtLink>
        <NuxtLink
          @click="close()"
          to="/knowledge"
          class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
        >
          <h5>Knowledge</h5>
        </NuxtLink>
        <NuxtLink
          @click="close()"
          to="/contact-us"
          class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
        >
          <h5>Contact</h5>
        </NuxtLink>
        <!-- <Disclosure>
          <DisclosureButton
            class="hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6 w-full"
          >
            <div class="flex justify-between w-full">
              <h5>Equipment</h5>
              <ChevronDownIcon class="h-6 w-6 text-blue-light" />
            </div>
          </DisclosureButton>
          <DisclosurePanel>
            <NuxtLink
              to="/equipment/categories/waste"
              class="pl-5 hover:bg-indigo-50 hover:text-indigo-700 border-b border-blue-lightest block pl-3 pr-4 py-4 text-base sm:pl-5 sm:pr-6"
            >
              <h5>Waste</h5>
            </NuxtLink>
          </DisclosurePanel>
        </Disclosure> -->
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  BellIcon,
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/vue/24/outline";

export default {
  data() {
    return {
      showEquipmentMenu: false,
    };
  },

  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    Bars3Icon,
    XMarkIcon,
    ChevronDownIcon,
  },
};
</script>
