<template>
  <footer class="max-w-8xl m-auto pb-16 pt-10">
    <div class="p-4 space-y-4 md:p-0 md:flex">
      <div class="md:w-1/4 md:pl-4">
        <NuxtLink to="/">
          <nuxt-img
            sizes="sm:90px md:100px lg:150px"
            alt="Justhire Logo"
            src="/images/logos/just-hire-logo.svg"
            loading="lazy"
          />
        </NuxtLink>
      </div>
      <div class="md:w-1/4 pt-2">
        <h4 class="font-semibold text-black-mid">Stay Connected</h4>
        <p class="text-blue-steel text-sm w-4/5 mt-2 mb-3">
          If you need to contact us for any reason please drop us an
          email, we'll aim to get back to you with hours
        </p>
        <a
          href="mailto:contact@justhire.com"
          class="text-green fw-400 h9 lh-21"
          >contact@justhire.com</a
        >
        <h4 class="font-semibold text-black-mid mt-4">Follow Us</h4>
        <div class="flex space-x-3 mt-2">
          <NuxtLink to="https://twitter.com/JustHireUK">
            <div
              class="w-[33px] h-[33px] bg-blue rounded-full flex items-center justify-center hover:bg-green"
            >
              <nuxt-img
                sizes="sm:90px md:100px lg:150px"
                alt="Justhire Logo"
                src="/images/icons/facebook.svg"
                loading="lazy"
              />
            </div>
          </NuxtLink>
          <NuxtLink
            to="https://www.facebook.com/Just-Hire-110837483958364"
          >
            <div
              class="w-[33px] h-[33px] bg-blue rounded-full flex items-center justify-center hover:bg-green"
            >
              <nuxt-img
                sizes="sm:90px md:100px lg:150px"
                alt="Justhire Logo"
                src="/images/icons/twitter.svg"
                loading="lazy"
              />
            </div>
          </NuxtLink>
          <NuxtLink to="https://www.instagram.com/justhireuk/">
            <div
              class="w-[33px] h-[33px] bg-blue rounded-full flex items-center justify-center hover:bg-green"
            >
              <nuxt-img
                sizes="sm:90px md:100px lg:150px"
                alt="Justhire Logo"
                src="/images/icons/instagram.svg"
                loading="lazy"
              />
            </div>
          </NuxtLink>
        </div>
      </div>
      <div class="md:w-1/4 pt-2">
        <h4 class="font-semibold text-black-mid">Equipment</h4>
        <p class="text-blue-steel text-sm mt-2">
          <NuxtLink to="/equipment/categories/waste">
            Waste
          </NuxtLink>
        </p>
      </div>
      <div class="md:w-1/4 pt-2">
        <h4 class="font-semibold text-black-mid">Useful links</h4>
        <p class="text-blue-steel text-sm mt-2">
          <NuxtLink to="/booking/location">Booking</NuxtLink>
        </p>
      </div>
    </div>
  </footer>
</template>
